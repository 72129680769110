const themes = [
  {
    name: 'light'
  },
  {
    name: 'dark',
  }

];

export default themes;