// import * as firebase from "firebase";
// import firestore from "firebase/firestore";
import firebase from "firebase/app";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyD1P0W03RDweOrpGZfjHdUGAwJV1Og9Pr8",
  authDomain: "notio-dev.firebaseapp.com",
  databaseURL: "https://notio-dev.firebaseio.com",
  projectId: "notio-dev",
  storageBucket: "notio-dev.appspot.com",
  messagingSenderId: "408343831357",
  appId: "1:408343831357:web:2023105552829ede669b54"
};
firebase.initializeApp(config);

var db = firebase.firestore();

export default db;
